@import "mixins";

//common styles

body {
  background-color: $bg-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

#app {
  height: 100vh;
}

.offset-main {
  margin-left: 70px;
  padding-top: 45px;
}

.structure {
  display: flex;
  flex-direction: row;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
}

.loader {
  left: 50%;
  top: 40%;
  z-index: 1000;
  position: absolute;
}

// Side bar styles
.sidebar--primary {
  height: 100vh;
  background-color: $primary;
  width: 70px;
  position: fixed;
  z-index: 999999999999;

  .nav-logo {
    img {
      width: 50px;
      display: block;
      margin: auto;
      padding: 10px 0px;
    }
  }
  .nav-item {
    .sidebar__link {
      .icon-label-group {
        padding: 22px 0px;

        &:hover {
          background-color: $black;
          svg {
            color: $secondary;
            path {
              stroke: $secondary;
            }
          }
        }
        &:focus {
          background-color: $black;
          svg {
            color: $secondary;
            path {
              stroke: $secondary;
            }
          }
        }
        &:visited {
          background-color: $black;
          svg {
            color: $secondary;
            path {
              stroke: $secondary;
            }
          }
        }

        svg {
          color: #c8cbd6;
          font-size: 26px;
          display: block;
          margin: auto;
          path {
            stroke: #c8cbd6;
          }
        }
      }
    }

    .sidebar__link.active {
      .icon-label-group {
        background-color: $black;
        svg {
          color: $secondary;
          path {
            stroke: $secondary;
          }
        }
      }
    }
  }
  .nav-item.logout {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 70px;
    cursor: pointer;
    .dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .dropdown-toggle {
        background-color: #6a7988;
        border-radius: 25px;
        padding: 10px;
        height: 35px;
        width: 35px;
        .sidebar__link {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          .icon-label-group {
            padding: 24px 0;
            &:hover,
            &:focus,
            &:visited {
              background-color: transparent;
              svg {
                color: $secondary;
              }
            }
            svg {
              font-size: 22px;
              color: $primary;
            }
          }
        }
        &::after {
          display: none;
        }
        &:hover,
        &:focus,
        &:visited {
          background-color: $black;
        }
      }
      .dropdown-menu {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.09);
        border: none;
      }
    }
  }
}
//loarder
.loader-container {
  z-index: 99999;
}

//Page general
.page {
  padding-left: 20px;
  padding-right: 20px;
}

//buttons genarel
.btn {
  font-size: 1.2em;
  border-radius: $border-radius1;
  border: none;
  font-size: 14px;
  padding: 8px 36px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  height: 36px;

  &.btn-primary {
    @include button-set($secondary);
    color: $white;
  }
  &.btn-cancel {
    @include button-set($white);
    color: $primary;
    border: 1px solid $primary;
  }
  &.btn-danger {
    @include button-set($danger);
    color: $white;
  }
  &.btn-link {
    border: none;
    background-color: transparent;
    color: $black;
    text-decoration: none;
    padding: 0 10px 0 10px;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
    &:active {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
.btn-csv {
  .btn {
    padding: 0px 12px;
  }
  .ic-export {
    svg {
      font-size: 20px;
    }
  }
  @include respond-min(smm) {
    margin-top: 20px;
  }
}
a {
  font-size: 12px;
  color: $primary;
}

//Filter Section
.filter-section {
  // display: flex;
  justify-content: space-between;
  margin-bottom: 45px;

  .filter-components {
    display: flex;
  }
  .btn-special.btn.btn-primary {
    padding: 8px 52px;
    svg {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.special {
  position: relative;
  .btn-user {
    position: absolute;
    top: 0;
    right: 0;
  }
  .btn-user.btn-Specification {
    position: relative;
  }
}

.btn-user-outline {
  position: absolute;
  top: 0;
  right: 0;
  .btn-special.btn.btn-primary {
    background-color: transparent;
    color: $secondary;
    border: 1px solid $secondary;
    svg {
      margin-right: 7px;
    }
    &:hover {
      background: $secondary;
      color: $white;
      transition: all 0.3s ease;
    }
    &:active {
      background: $secondary;
      color: $white;
    }
    &:focus {
        background: $secondary;
        color: $white;
    }
  }
}

//Page title
.page-title-row {
  display: flex;
  margin-bottom: 30px;

  .title {
    width: fit-content;

    h1 {
      color: $primary;
      font-size: 28px;
      font-weight: 400;
    }
    h3 {
      color: #707070;
      font-size: 19px;
      font-weight: 400;
    }
    .subtitle-1 {
      font-size: 12px;
      color: $grey;
      text-align: left;
    }
  }
}

//Form fields
.form {
  .form-select {
    border: 1px solid #d5dde5;
    border-radius: 4px;
    //padding: 8px 11px;
    background-color: #ffff;
    height: 36px;

    option {
      color: #202833;
    }
  }

  .input-group {
    height: 36px;
    .input-group-text {
      border: 1px solid #d5dde5;
      border-radius: 4px;
      padding: 8px 11px;
      background-color: #ffff;
      border-right: 0;
      padding-right: 0;

      svg {
        color: #d5dde5;
      }
    }

    .form-control {
      border-left: 0;
      background-color: #ffff;
    }
  }
}

//form 1
form {
  .form-label {
    font-size: $font-size-form-lbl;
    color: $grey;
    font-weight: 600;
  }
  .form-control {
    font-size: 0.875rem;
  }
  .form-select {
    font-size: 0.875rem;
    color: $grey;
  }
}
.dropdown-menu {
  font-size: 12px;
  box-shadow: $box-shadow1;
}
.dropdown-item {
  &:active {
    background-color: $secondary-dark;
  }
}
// form 2
.form-2 {
  .form-check-label {
    font-size: 14px;
    color: #202833;
  }
  .form-check-input {
    &:checked {
      background-color: #202833;
      border-color: #202833;
    }
  }
  .label-cust {
    font-size: 12px;
    color: #707070;
    font-weight: 600;
    padding-bottom: 8px;
  }
  .bottom-space {
    margin-bottom: 2rem;
  }
  .btn.btn-secondary.btn-sm.dropdown-toggle {
    background-color: $white;
    color: #616161;
    border: 1px solid #ced4da;
    text-align: left;
    padding: 0.25rem 0.5rem;
    &:after {
      right: 16px;
      position: absolute;
      top: 44%;
    }
  }
}
.form-check {
  &:hover {
    cursor: pointer;
  }
}
.form-check-input {
  &:checked {
    background-color: $danger;
    border: 1px solid $danger;
  }
}

//Table styles
.table {
  margin-bottom: 1.8rem;
  tr {
    border: 0px;
    border-bottom-color: #d5dde5 !important;
    vertical-align: middle;

    th {
      color: #707070;
      font-size: 12px;
      font-weight: 500;
      border-color: #d5dde5;
      border-bottom-color: #d5dde5 !important;
      padding: 8px;
    }

    td {
      color: #202833;
      font-size: 14px;
      font-weight: 400;
      background-color: #ffff;
      padding: 12px 8px;

      .dot-menu-wrap {
        .button {
          width: fit-content;
          display: block;
          margin: auto;
          height: auto;
          padding: 5px 7px;
          cursor: pointer;
          border-radius: 4px;

          svg {
            font-size: 22px;
            color: #707070;
          }

          &:hover {
            background-color: #e9e9e9;
          }
        }
      }

      .status {
        .active {
          color: #129535;
          text-transform: uppercase;
          font-weight: bold;
        }

        .inactive {
          color: $danger;
          text-transform: uppercase;
          font-weight: bold;
        }
        .new {
          color: #E3B748;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .dropdown {
        svg {
          color: $black;
        }
        .dropdown-toggle.btn.btn-link {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

//Pagination
.pagination {
  justify-content: end;
  .page-item {
    .page-link {
      color: $primary;
      height: 36px;
      width: 36px;
      text-align: center;
    }
  }
  .page-item.active {
    .page-link {
      background-color: $secondary;
      color: $white;
      border-color: $secondary;
    }
  }
}

//modal
.modal-dialog {
  .modal-content {
    padding: 4px 6px;
    .modal-header {
      border-bottom: none;
      padding-bottom: 0;

      .modal-title {
        color: #202833;
        font-size: 28px;
        font-weight: 400;
      }

      .modal-title.question {
        color: #202833;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .modal-body {
      font-size: 14px;
      color: #707070;

      .form-label {
        font-size: 12px;
      }
      .form-control {
        font-size: 0.875rem;
      }
    }
    .modal-footer {
      border-top: none;
    }
  }
}
//modal-side

.offcanvas {
  background-color: $bg-color2;
  .offcanvas-header {
    padding: 42px 36px 16px 16px;
    .offcanvas-title.h5 {
      font-size: 22px;
    }
  }
  .offcanvas-body {
    form {
      .frm-content {
        padding: 22px 24px;
        background-color: $white;
        box-shadow: $box-shadow1;
      }
      .frm-btn {
        margin-top: 32px;
      }
    }
  }
}

//status
.status {
  .active {
    color: #129535 !important;
    text-transform: uppercase;
    font-weight: bold;
  }

  .inactive {
    color: $danger !important;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.help-text {
  font-size: 12px;
}
.wrap-error-message {
  // position: absolute;
  bottom: 10%;
  width: 300px;
  height: 50px;
  background: white;
  .error-message {
    padding-top: 10px;
    text-align: center;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;

  }
}
