@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


$breakpoints: (
  xs: 576px,
  sm: 768px,
  smm: 767px,
  md: 992px,
  ms: 1024px,
  lg: 1200px,
  xl: 1600px,
  xxl: 2054px,
);
@mixin respond-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}

@mixin respond-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value)) {
      @content;
    }
  }
}

@mixin respond-between($down, $up) {
  @if map-has-key($breakpoints, $down) and map-has-key($breakpoints, $up) {
    $down-breakpoint: map-get($breakpoints, $down);
    $up-breakpoint: map-get($breakpoints, $up);
    @media (min-width: $down-breakpoint) and (max-width: ($up-breakpoint)) {
      @content;
    }
  }
}
//colorus
$white: #ffffff;
$black: #0C0F14;
$primary: #202833;
$secondary: #63AB45;
$secondary-dark: #505D28;
$danger: #DF0B28;
$grey: #707070;
$bg-color: #F7F8FA;
$bg-color2: #F2F4F8;

//genarel
$font-family-base: 'Poppins', sans-serif;
$font-size-base: 16px;
$font-size-form-lbl: 12px;
$box-shadow1: #DDE4EECC 0px 2px 8px 5px;
$transition: 0.5s;
$border-radius1: 4px;

//buttons
@mixin button-set($btn) {
    background: $btn;
    &:hover {
      background: darken($btn, 8%);
      transition: all 0.3s ease;
    }
    &:active {
      background: darken($btn, 25%);
    }
    &:focus {
        background: darken($btn, 25%);  
    }
  }
 
